import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import LandingPage from "../views/Landing/Landing"
import ColdRooms from "../views/Services/Service1";
import InsulatedPanels from "../views/Services/Service2";
import RefrigerationSystems from "../views/Services/Service3";
import InsulatedDoors from "../views/Services/Service4";
import ColdRoomAccessories from "../views/Services/Service5";
import ControlPanels from "../views/Services/Service6";
import RefrigerationControllers from "../views/Services/Service7";
const hist = createBrowserHistory();

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router history={hist}>
          <Switch>
            <Route path="/coldRooms" component={ColdRooms} />
            <Route path="/refrigerationSystems" component={RefrigerationSystems} />
            <Route path="/insulatedPanels" component={InsulatedPanels} />
            <Route path="/insulatedDoors" component={InsulatedDoors} />
            <Route path="/coldRoomAccessories" component={ColdRoomAccessories} />
            <Route path="/controlPanels" component={ControlPanels} />
            <Route path="/refrigerationControllers" component={RefrigerationControllers} />
            <Route  path="/" component={LandingPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;