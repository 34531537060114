import React, { Component } from 'react';

import Header from './components/Header';
import JumbotronSection from './components/Jumbotron';
import DescriptionSection from './components/RefrigerationControllers';

import ContactSection from '../../components/ContactSection/Contact';
import FooterSection from '../../components/FooterSection/Footer';


export default function Service({...rest}) {
    

    return (
        <div className="homepage-6">
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            <div className="main">
                <Header imageData={"/Fridgles Brochure/Brands/Logo.png"} {...rest}/>
                <JumbotronSection {...rest}/>
                <DescriptionSection {...rest}/>
                <ContactSection {...rest}/>
                <FooterSection {...rest}/>
            </div>
        </div>
    );
}