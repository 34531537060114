import React, { PureComponent } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import Slider from "./SliderJumbotron"

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

class ControlPanels extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }

    render() {
        return (
            <section className="section discover-area overflow-hidden ptb_84 bg-gray">
                  <div className="container-fluid" id="refrigeration-banner-container">
                <div className="col-12 col-lg-12" id="refrigeration-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron6">
                                    <div className="centered"><h3>CONTROL PANELS FOR COLDROOMS <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2 text-center">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4" id="problem"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center" ref={this.scrollDiv}>  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   
                               </div>
                            </div>
                </div>
                <div className="container mt-5">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Discover Text */}
                            <div className="discover-text pt-4 pt-lg-0">
                            <h2 className="pb-4 pb-sm-0">Control Panels</h2>
                            <p className="pt-3 pb-4">We provide wide variety of electrical control panels for various commercial & industrial purpose 
                            based on customer's requirement. Customers can select from pre produces control panels or it can be designed & manufactured based on customer demand.</p>
                            <h3 className="pt-4 pb-3">Types of Control Panels:</h3>
                                        {/* Check List */}
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <ul className="check-list">
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">Control panels for cold room application</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">Control panels for temperature controlled warehouses</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">Control panels for dry warehouses</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">Control panels for residential purpose</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">Control panels for any other residential & commercial purpose</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p className="pt-4 pb-3">We also provide branded control panels for cold room applications sourced from European manufacturers.</p>
                                    </div>
                                </div>
                        <Hidden smDown>
                            <div className="col-12 col-lg-6">
                                <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                                    <Slider/>
                                    {/* <img src="Fridgles Brochure/Corrugated-insulated-panels.jpg" alt="" /> */}
                                </div>
                            </div>
                        </Hidden>
                    
                    </div>
                </div>
           </div>
           </section>
        );
    }
}

export default ControlPanels




