import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

class FooterSection extends Component {
    state = {
        data: {
            linkText_1: "Useful Links",
            linkText_2: "Coldroom Services",
            linkText_3: "Refrigeration Services"
        },
        iconList: [
            {
                "id": 1,
                "link": "facebook",
                "iconClass": "fab fa-facebook-f"
              },
              {
                "id": 2,
                "link": "twitter",
                "iconClass": "fab fa-twitter"
              },
              {
                "id": 3,
                "link": "google-plus",
                "iconClass": "fab fa-google-plus-g"
              },
              {
                "id": 4,
                "link": "linkedin-in",
                "iconClass": "fab fa-linkedin-in"
              }
        ],
        footerList_1: [
            {
                text: "Home",
                link: "#"
            },
            {
                text: "About Us",
                link: "#about-us"
            },
            {
                text: "Services",
                link: "#services"
            },
            {
                text: "Contact",
                link: "#contact"
            },
        ],
        footerList_2: [
            {
                name: "Cold Rooms",
                link: "/coldRooms"
            },
            {
                name: "Insulated Panels",
                link: "/insulatedPanels"
            },
            {
                name: "Cold Room Accessories",
                link: "/coldRoomAccessories"
            },
            {
                name: "Insulated Doors & Accessories",
                link: "/insulatedDoors"
            },
        ],
        footerList_3: [
            {
                name: "Control Panels",
                link: "/controlPanels"
            },
            {
                name: "Refrigeration Systems",
                link: "/refrigerationSystems"
            },
            {
                name: "Refrigeration Controllers",
                link: "/refrigerationControllers"
            }

        ]
    }
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 iconList: res.data.iconList,
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    render() {
        return (
            <div id="footer-fridgles">
                {/* <div> */}
                <footer >
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" >
                                    <img className="logo" src="/Fridgles Brochure/Fridgles logo white.svg" style={{height: 60, width: 300}} alt="" />
                                </a>
                                <p className="mt-2 mb-3">{this.state.data.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_1}</h3>
                                <ul>
                                    {this.state.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2 scroll"><a href={item.link}>{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_2}</h3>
                                <ul>
                                    {this.state.footerList_2.map((item, idx) => {
                                        return(
                                            <li key={`flt_${idx}`} className="py-2"><a href={item.link}>{item.name}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_3}</h3>
                                {/* Store Buttons */}
                                {/* {/* <div className="button-group store-buttons store-black d-flex flex-wrap"> */}
                                <ul>
                                    {this.state.footerList_3.map((item, idx) => {
                                  
                                            return(
                                                <li key={`flt_${idx}`} className="py-2"><a href={item.link}>{item.name}</a></li>
                                            );
                                       
                                    })}
                                    </ul>
                                </div> 
                             </div>
                         </div> 
                        </div>
                        </div>
                    {/* </div> */}
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2021 Fridgles Integrated Solutions.</div>
                                {/* Copyright Right */}
                                {/* <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">Theme Land</a></div> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;