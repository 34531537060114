import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      '/Fridgles Brochure/ColdRooms/1.jpg',
  },
  {
    label: 'Bird',
    imgPath:
      '/Fridgles Brochure/ColdRooms/2.jpg',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      '/Fridgles Brochure/ColdRooms/3.jpg',
  },
  {
    label: 'Bird',
    imgPath:
      '/Fridgles Brochure/ColdRooms/4.jpg',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      '/Fridgles Brochure/ColdRooms/5.jpg',
  },
];



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 100,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: `200px`,
    display: 'block',
    maxWidth: `100%`,
    overflow: 'hidden',
    
  },
}));

function ColdRoomSlider() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
       
   
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
             <img  id="cold-pic" src={step.imgPath}  />
            ) : null}
            
          </div>
        ))}
      </AutoPlaySwipeableViews>

    </div>
  );
}

export default ColdRoomSlider;
