import React, { Component } from 'react';

import Header from './components/Header';
import HeroSection from './components/Hero';
import AboutSection from './components/About';
import ServiceSection from './components/Services';
import BrandSection from "./components/Brands"

import ContactSection from '../../components/ContactSection/Contact';
import FooterSection from '../../components/FooterSection/Footer';
import HeroFive from '../../components/HeroSection/HeroFive';
import HeroOne from '../../components/HeroSection/HeroOne';
import HeroTwo from '../../components/HeroSection/HeroTwo';
import HeroThree from '../../components/HeroSection/HeroThree';

class LandingPage extends Component {
    render() {
        return (
            <div className="homepage-6">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                
                    <Header imageData={"/Fridgles Brochure/Brands/Logo.png"} />
                 {/* <HeroThree/> */}
                    <HeroSection />
                    <AboutSection />
                    <ServiceSection/>
                    <BrandSection/>
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default LandingPage;