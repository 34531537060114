import React, { Component } from 'react';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';


const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneFeatureSection";
const DIR = "/Fridgles Brochure/services/";
const images = [".png",".png",".png",".jpg",".jpg",".png",".png",".jpeg",".png",".jpg",".png",".png",".png",".jpg",".png",".JPG",".jpg",".png",".png",".jpg"]
class FeatureSection extends Component {
    state = {
        data: {},
        featureDataTwo: [],
        services: [
            {
                heading: "Cold Rooms",
                description: "Complete Turnkey Project Solutions for Cold Storage facilities.",
                src: '.jpeg',
                link: "/coldRooms"
            },
            {
                heading: "Insulated Panels",
                description: "Polyurethane insulated sandwich panels for walls, ceiling and floor.",
                src: '.jpg',
                link: "/insulatedPanels"
            },
            {
                heading: "Refrigeration Systems",
                description: "Wide range of refrigeration equipments to select for any specific requirement.",
                src: '.jpg',
                link: "/refrigerationSystems"
            },
            {
                heading: "Insulated Doors & Accessories",
                description: "Insulated doors and door accessories as per choice of polyurethane insulation.",
                src: '.jpg',
                link: "/insulatedDoors"
            },
            {
                heading: "Cold Room Accessories",
                description: "One window solution for all types of cold room accessories and refrigeration accessories.",
                src: '.jpg',
                link: "/coldRoomAccessories"
            },
            {
                heading: "Control Panels",
                description: "Wide variety of electrical control panels for various commercial & industrial purpose.",
                src: '.jpg',
                link: "/controlPanels"
            },
            {
                heading: "Refrigeration Controllers",
                description: "Advanced refrigeration controllers are available for various industrial and commercial applications.",
                src: '.jpg',
                link: "/refrigerationControllers"
            },
            {
                heading: "Copper tubes with fittings",
                description: "Advanced refrigeration controllers are available for various industrial and commercial applications.",
                src: '.jpeg',
                link: "/refrigerationControllers"
            },
            {
                heading: "Water chillers",
                description: "Advanced refrigeration controllers are available for various industrial and commercial applications.",
                src: '.jpeg',
                link: "/refrigerationControllers"
            }
        ]
    }
   
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    featureDataTwo: res.data.featureDataTwo
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="services" className="section features-area pb-5 pt-5">
                <div className="container" id="services-text">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-12">
                        {/* Section Heading */}
                        
                        <div className="section-heading text-center">
                     
                        <h2>{"Services"}</h2>
                        {/* <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p> */}
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                      {
                        this.state.services.map((value,index) => {
                          return(
                        
                            <div className="col-12 col-md-6 col-lg-4">
                              <div className="icon-box text-center services-box">
                                  <h6 className="p-2 mt-2">{value.heading}</h6>
                                  <div id="container">
                                
                                  <div className="featured-icon mb-3">
                                      <img src={DIR+index+value.src} />
                                  </div>
                                  <div className="overlay">
                                      <div className="text">
                                          <p>{value.description}</p>
                                          <a href={value.link}><strong>Read More...</strong></a> 
                                      </div>
                                  </div>
                                
                                  </div>
                              </div>
                            </div>
                          
                          );
                        })
                      }    
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;
