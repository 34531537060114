import React, { PureComponent, createRef } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import Slider from "./SliderJumbotron"
import Jumbotron from './InsulatedPanelsComponents/Jumbotron';
import PanelSlider from './InsulatedPanelsComponents/PanelSlider';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

class InsulatedPanels extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    scrollDiv = createRef();

    scrollSmoothHandler = () => {
      this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    };
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }

    render() {
        return (
            <section className="section discover-area overflow-hidden bg-gray ptb_84">
                 <div className="container-fluid" id="refrigeration-banner-container">
                <div className="col-12 col-lg-12" id="refrigeration-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron2">
                                    <div className="centered"><h3>INSULATED SANDWICH PANELS <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2 text-center">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4" id="problem"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center" ref={this.scrollDiv}>  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   
                               </div>
                            </div>
                </div>
                <div className="container mt-5">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6 mb-5">
                            {/* Discover Text */}
                            <div className="discover-text pt-4 pt-lg-0">
                            <h2 className="pb-4 pb-sm-0">Insulated Panels</h2>
                            <p className="pt-3 pb-4">We supply and install polyurethane insulated sandwich panels for walls, ceiling and floor that
                            are used in building cold rooms for chiller and freezer applications. Sandwich panels are available in folloeing thicknesses: </p>
                                        {/* Check List */}
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5">
                                                <ul className="check-list">
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">50mm</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">80mm</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">100mm</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">120mm</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-5 col-md-5">
                                                <ul className="check-list">
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">150mm</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">170mm</span>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        {/* List Box */}
                                                        <div className="list-box media">
                                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                            <span className="media-body pl-2">200mm</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <p className="pt-3 pb-4">Panels are insulated with CFC free polyurethane and are locked securely together
                                        with cam locks with steel hooks. Cam locks are tightly joined by a tongue groove system into monolithic assembly. Modular insulated rooms are easily taken 
                                        apart and reassembled in another location.</p>
                                    </div>
                        </div>
                      
                            <div className="col-12 col-lg-6 mb-5">
                                <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                                    <PanelSlider/>
                                    {/* <img src="Fridgles Brochure/Corrugated-insulated-panels.jpg" alt="" /> */}
                                </div>
                            </div>
                       
                    </div>                    
                </div>
                </div>
                </section>
        

        );
    }
}

export default InsulatedPanels


