import React, { PureComponent } from 'react'
import axios from 'axios';
import SliderRefSys from './SliderRefSys';
import EvapouraterSlider from './EvapouraterSlider';
import Hidden from "@material-ui/core/Hidden/Hidden"

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";
class Evapouraters extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div>
            <Hidden smDown>
                    <div className="row justify-content-between">
                         <div className="col-lg-6 mt-5" id="checkin">
                               <EvapouraterSlider/>
                           </div>
                        <div className="col-12 col-lg-6">
                            <div className="discover-text pt-4 pt-lg-0">
                            <h2 className="pb-4 pb-sm-0 ">Evaporaters</h2>
                        <p className="pt-4 pb-3">We offer wide range of refrigeration equipments to select for any specific requirement. We suuply and install branded refrigeration equipments for freezer and Chiller applications that includes:</p>
                                    {/* Check List */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Condensing Units</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Evaporators & Air Coolers</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Mono-Block Units</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Power Packs</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Blast Freezers</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <p className="pt-4 pb-3">
                                    The refrigeration systems are supplied to offer:</p>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Increased Efficiency</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Reduced Operational Cost</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Ease of Service and Installation</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Better Value for Money</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Environment Friendly</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                        
                                    <p className="pt-4 pb-3">
                                    Refrigeration equipments are sourced from leading brands such as:</p>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Bitzer</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Copeland</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Frigabohn</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Lu-Ve</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Dorin</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Gunter</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Carrier</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Danfoss</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        {/* <Hidden smDown> */}
                          
                        {/* </Hidden> */}
                        
                    </div>
            </Hidden>

            <Hidden smUp>
            <div className="row justify-content-between">
                       
                        <div className="col-12 col-lg-6">
                            <div className="discover-text pt-4 pt-lg-0">
                            <h2 className="pb-4 pb-sm-0 ">Evaporaters</h2>
                        <p className="pt-4 pb-3">We offer wide range of refrigeration equipments to select for any specific requirement. We suuply and install branded refrigeration equipments for freezer and Chiller applications that includes:</p>
                                    {/* Check List */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Condensing Units</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Evaporators & Air Coolers</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Mono-Block Units</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Power Packs</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Blast Freezers</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <p className="pt-4 pb-3">
                                    The refrigeration systems are supplied to offer:</p>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Increased Efficiency</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Reduced Operational Cost</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Ease of Service and Installation</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Better Value for Money</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Environment Friendly</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                        
                                    <p className="pt-4 pb-3">
                                    Refrigeration equipments are sourced from leading brands such as:</p>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Bitzer</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Copeland</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Frigabohn</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Lu-Ve</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Dorin</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Gunter</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Carrier</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Danfoss</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        {/* <Hidden smDown> */}
                          
                        {/* </Hidden> */}
                        <div className="col-lg-6 mt-5" id="checkin">
                               <EvapouraterSlider/>
                           </div>
                    </div>
            </Hidden>
            </div>
        );
    }
}

export default Evapouraters

