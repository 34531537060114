import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default function Header(props) {
  const state = {
    navLinks : [
        { link: "/", name: "Home", type: 'simple'},
        { link: "#about-us", name: "About Us", type: 'simple'},
        { link: "#services", name: "Products & Services", type: 'dropdown', 
          options: [
            {
                name: "Cold Rooms",
                link: "/coldRooms"
            },
            {
                name: "Insulated Panels",
                link: "/insulatedPanels"
            },
            {
                name: "Refrigeration Systems",
                link: "/refrigerationSystems"
            },
            {
                name: "Insulated Doors & Accessories",
                link: "/insulatedDoors"
            },
            {
                name: "Cold Room Accessories",
                link: "/coldRoomAccessories"
            },
            {
                name: "Control Panels",
                link: "/controlPanels"
            },
            {
                name: "Refrigeration Controllers",
                link: "/refrigerationControllers"
            }
          ]
          
        },
        { link: "#contact", name: "Contact Us", type: 'simple'},
    ]
  }
  return (
    <div id="nav">
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark" style={{background:"#0C141D"}}>
        <div className="container-fluid position-relative">
                <a className="navbar-brand" href="/">
                <img className="d-none d-md-block navbar-brand-regular" src={"/Fridgles Brochure/Fridgles logo white.svg"} alt="brand-logo"  style={{height:50}}/>
                  {/* <img className="d-none d-md-block navbar-brand-sticky" src={"/Fridgles Brochure/Fridgles logo white.svg"} alt="sticky brand-logo" style={{height:60}}/> */}
                  <img className="d-md-none navbar-brand-regular" src={"/Fridgles Brochure/icon.svg"} alt="brand-logo"  style={{height:50}}/>
                  <img className="d-md-none navbar-brand-sticky" src={"/Fridgles Brochure/icon.svg"} alt="brand-logo"  style={{height:50}}/>
                </a>
                <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-inner">
                    {/*  Mobile Menu Toggler */}
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <nav>
                        <ul className="navbar-nav" id="navbar-nav">
                            
                            {
                              state.navLinks.map((navLink, index)=>{
                                console.log(navLink)
                                if(navLink.type==="dropdown") 
                                  return(
                                    <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle"id="navbarDropdownMenuLink" href={navLink.link} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {navLink.name}
                                      </a>
                                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        {
                                          navLink.options.map((opt,idx)=>{
                                            return(
                                              <li>
                                                <a className="dropdown-item" href={opt.link}>{opt.name}</a>
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </li>  
                                  )
                                  else if(navLink.link==='#about-us' || navLink.link==='#contact'){
                                    return(
                                      <li className="nav-item">
                                          <a className="nav-link scroll"  href={navLink.link}>{navLink.name}</a>
                                      </li>              
                                    )
                                  }
                                else
                                  return(
                                    <li className="nav-item">
                                        <a className="nav-link "  href={navLink.link}>{navLink.name}</a>
                                    </li>              
                                  )
                              })
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    </div>
  )
}

