import React, { PureComponent } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import Slider from "./SliderJumbotron"
import InsulatedDoorsJumbotron from './InsulatedDoorsComponents/InsulatedDoorsJumbotron';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";

class InsulatedDoors extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }

    render() {
        return (
        <section className="section discover-area overflow-hidden ptb_84 bg-gray">
             <div className="container-fluid" id="refrigeration-banner-container">
                <div className="col-12 col-lg-12" id="refrigeration-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron4">
                                    <div className="centered"><h3>WIDE RANGE OF INSULATED DOORS <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
                                </div>
                                    {/* <img src={this.state.data.thumbOne} alt="" /> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2 text-center">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4" id="problem"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   
                               </div>
                            </div>
                </div>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 ">
                    {/* Discover Text */}
                    <div className="discover-text pt-4 pt-lg-0">
                    <h2 className="pb-4 pb-sm-0">Insulated Doors</h2>
                    <p className="pt-3 pb-4">We provide all types of insulated doors and door accessories as per choice of polyurethane insulation in different thicknesses and applications.
                    Theses doors are usually mounted on industrial sandwich panels but it is also possible to  install them on traditional masonry.</p>
                    <h3 className="pt-4 pb-3">Types of Doors:</h3>
                                {/* Check List */}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <ul className="check-list">
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Electrical Operated Sliding Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Manual Sliding Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Hinged Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Overhead Sectional Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Roller Shutters</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <ul className="check-list">
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">High Speed Rollup Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Folding Doors</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Traffic swing type doors with glass port</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Sectional doors & many more..</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className="pt-4 pb-3">Accessories:</h3>
                                {/* Check List */}
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <ul className="check-list">
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Door Handle</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Hinges</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Frames</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Gaskets</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <ul className="check-list">
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Safety Release Handles</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Heat Wires</span>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                {/* List Box */}
                                                <div className="list-box media">
                                                    <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                    <span className="media-body pl-2">Railings</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <Hidden smDown>
                        <div className="col-12 col-lg-6">
                            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                                <Slider/>
                                {/* <img src="Fridgles Brochure/Corrugated-insulated-panels.jpg" alt="" /> */}
                            </div>
                        </div>
                    </Hidden>

                </div>

                </div>
           </div>
           </section>
        );
    }
}

export default InsulatedDoors



