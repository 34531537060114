import React, { PureComponent, createRef } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import Slider from "./SliderJumbotron"
import ColdRoomJumbotron from './ColdRoomComponents/ColdRoomJumbotron';
import ColdRoomSlider from './ColdRoomComponents/ColdRoomSlider';
const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";
class ColdRooms extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }

    scrollDiv = createRef();

    scrollSmoothHandler = () => {
      this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    };
  
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section discover-area overflow-hidden bg-gray ptb_84">
                <div className="container-fluid" id="coldroom-banner-container">
                <div className="col-12 col-lg-12" id="coldroom-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron1">
   <div className="centered"><h3>TURNKEY SOLUTION FOR COLD ROOMS <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
  
   </div>
</div>
                                    {/* <img src={this.state.data.thumbOne} alt="" /> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2 text-center">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4" id="problem"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center" ref={this.scrollDiv}>  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   
                               </div>
                            </div>
                </div>
                <section >
                <div className="container" >
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            <div className="discover-text pt-4 pt-lg-0">
                                <h2 className="pb-4 pb-sm-0"  >Cold Rooms</h2>
                                <p className="pt-3 pb-4">
                                Complete Turnkey project Solutions for Cold Storage facilities with varied
                                operating temperature ranges from -40<sup>o<sub>C</sub></sup> to +40<sup>o<sub>C</sub></sup> based on customer's requirement. We provide high quality
                                cold storage solutions for any industrial refrigeration application:</p>
                                <ul className="check-list">
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Food Processing</span>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Beverage Industry</span>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Multi-Purpose Cold Storage</span>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Pre-Cooling and Freezing Systems</span>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Slaughterhouses (Meat and Poultry)</span>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        {/* List Box */}
                                        <div className="list-box media">
                                            <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                            <span className="media-body pl-2">Chemical and Pharmaceutical</span>
                                        </div>
                                    </li>
                                </ul>
                                <p className="pt-3 pb-4">
                                With superior choice, performance, quality, flexibility
                                and price - Fridgles is the best choice for cold storage solutions</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <ColdRoomSlider/>
                            
                        </div>
                        
                        {/* <Hidden smDown> */}
                           
                        {/* </Hidden> */}
                        
                    </div>
                </div>
                </section>
                
            </section>
        );
    }
}

export default ColdRooms

