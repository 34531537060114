import React, { Component } from 'react';

const initData = {
    heading: "Capture and share your best memories with sApp",
    content: " ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png"
}

const slides = [
    "/Fridgles Brochure/Bg.jpg",
    "/Fridgles Brochure/Bg1.jpg",
    "/Fridgles Brochure/Bg2.jpg",
  
]
class HeroSection extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div id="home" className="section bg-overlay5 overflow-hidden d-flex align-items-center">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block w-100" src={slides[0]} alt="First slide"/>
                <div className="carousel-caption d-none d-md-block" id="animating">
                    <h5 className="animating-text1">COLD ROOMS</h5>
                    <p className="animating-text2"></p>
                </div>
                <div class="carousel-caption">
                   <h5 className="company-name">FRIDGLES INTEGRATED SOLUTIONS</h5>
                </div>
                <div className="black-overlay"></div>
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={slides[1]} alt="Second slide"/>
                <div className="carousel-caption d-none d-md-block" id="animating">
                    <h5 className="animating-text1">INSULATED PANELS</h5>
                    <p className="animating-text2"></p>
                </div>
                <div class="carousel-caption">
                   <h5 className="company-name">FRIDGLES INTEGRATED SOLUTIONS</h5>
                </div>
                <div classNameName="black-overlay"></div>
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={slides[2]} alt="Third slide"/>
                <div className="carousel-caption d-none d-md-block" id="animating">
                    <h5 className="animating-text1">CONTROL PANELS</h5>
                    <p className="animating-text2"></p>
                </div>
                <div class="carousel-caption">
                   <h5 className="company-name">FRIDGLES INTEGRATED SOLUTIONS</h5>
                </div>
                <div className="black-overlay"></div>
              </div>
    
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
          </div>
        );
    }
}

export default HeroSection;