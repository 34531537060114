import React, { Component } from 'react';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';


const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneFeatureSection";
const DIR = "/Fridgles Brochure/services/";
const images = [".png",".png",".png",".jpg",".jpg",".png",".png",".jpeg",".png",".jpg",".png",".png",".png",".jpg",".png",".JPG",".jpg",".png",".png",".jpg"]
class FeatureSection extends Component {
    state = {
        data: {},
        featureDataTwo: [],
        services: [
            {
                heading: "Cold Rooms",
                description: "Complete Turnkey Project Solutions for Cold Storage facilities.",
                src: '.jpg',
                link: "/coldRooms"
            },
            {
                heading: "Insulated Panels",
                description: "Polyurethane insulated sandwich panels for walls, ceiling and floor.",
                src: '.jpg',
                link: "/insulatedPanels"
            },
            {
                heading: "Refrigeration Systems",
                description: "Wide range of refrigeration equipments to select for any specific requirement.",
                src: '.jpg',
                link: "/refrigerationSystems"
            },
            {
                heading: "Insulated Doors & Accessories",
                description: "Insulated doors and door accessories as per choice of polyurethane insulation.",
                src: '.jpg',
                link: "/insulatedDoors"
            },
            {
                heading: "Cold Room Accessories",
                description: "One window solution for all types of cold room accessories and refrigeration accessories.",
                src: '.jpg',
                link: "/coldRoomAccessories"
            },
            {
                heading: "Control Panels",
                description: "Wide variety of electrical control panels for various commercial & industrial purpose.",
                src: '.jpg',
                link: "/controlPanels"
            },
            {
                heading: "Refrigeration Controllers",
                description: "Advanced refrigeration controllers are available for various industrial and commercial applications.",
                src: '.png',
                link: "/refrigerationControllers"
            }
        ]
    }
   
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    featureDataTwo: res.data.featureDataTwo
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="about-us" className="section features-area ptb_100">
                <div className="container">
                
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        
                        <div className="section-heading text-center">
                        {/* <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                            <i className="far fa-lightbulb text-primary mr-1" />
                            <span className="text-primary">Premium</span> {"Services"}
                        </span> */}
                        <h2>{"About Us"}</h2>
                        </div>
                        <div className="text-center">
                        <p id="aboutus-animation" className="mt-2">Fridgles Integrated Solutions was founded by a dynamic team of individuals
                        having a decade of experience in MENA region in the field of cold room and commercial refrigeration industry.</p>
                        <p className="mt-2">Our aim is to transform the cold storage and commecial refrigeration industry by leveraging our expertise to cater 
                            the demand of cold storage industry in Pakistan.
                        </p>
                        <p className="mt-2">Fridgles provides innovative and high quality products and services that meet the needs of our customers</p>
                        <a href=""><strong>Read More...</strong></a>
                        {/* <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p> */}
                        </div>
                    </div>

                    <div className=" col-12 col-lg-6 text-center">
                        
                        <img src="/Fridgles Brochure/Blue Beach Plain Collage Facebook Post.jpg" style={{height: 350}}></img>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;
