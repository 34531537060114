import React, { PureComponent } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import JumbotronRef from './RefrigerationSystemsComponents/JumbotronRef';
import SliderRefSys from './RefrigerationSystemsComponents/SliderRefSys';
import Evapouraters from './RefrigerationSystemsComponents/Evapouraters';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';



const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";
class RefrigerationSystems extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    
    render() {
       
        return (
            <section className="section discover-area overflow-hidden bg-gray ptb_84">
                <div className="container-fluid" id="refrigeration-banner-container">
                <div className="col-12 col-lg-12" id="refrigeration-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron3">
                                    <div className="centered"><h3>WIDE RANGE OF INSULATED DOORS <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
                                </div>
                                    {/* <img src={this.state.data.thumbOne} alt="" /> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 ml-2">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   
                                   <div className="col-lg-2 ml-2">  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 ml-2">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                               </div>
                            </div>
                </div>
                <div className="container mb-5 mt-lg-2" id="ref-sys-desc">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6 mt-5">
                        <Card >
      <CardContent>
                            <div className="discover-text pt-4 pt-lg-0">
                            <h2 className="pb-4 pb-sm-0 ">Condensing units</h2>
                        <p className="pt-4 pb-3">We offer wide range of refrigeration equipments to select for any specific requirement. We suuply and install branded refrigeration equipments for freezer and Chiller applications that includes:</p>
                                    {/* Check List */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Condensing Units</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Evaporators & Air Coolers</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Mono-Block Units</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Power Packs</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Blast Freezers</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <p className="pt-4 pb-3">
                                    The refrigeration systems are supplied to offer:</p>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Increased Efficiency</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Reduced Operational Cost</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Ease of Service and Installation</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Better Value for Money</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Environment Friendly</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                        
                                    <p className="pt-4 pb-3">
                                    Refrigeration equipments are sourced from leading brands such as:</p>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Bitzer</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Copeland</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Frigabohn</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Lu-Ve</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Dorin</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Gunter</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Carrier</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Danfoss</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </CardContent>
                            </Card>
                            </div>
                           
                        {/* <Hidden smDown> */}
                           <div className="col-lg-6 mt-5">
                               <SliderRefSys/>
                           </div>
                        {/* </Hidden> */}
                        
                    </div>
                  
                      <div className="mt-5">
                      <Evapouraters/>
                      </div>
                           
                       
                    
               
                </div>
           </div>
           </section>
        );
    }
}

export default RefrigerationSystems

