import React, { Component } from 'react';
import ContactForm from './ContactForm';
import axios from 'axios';
import SimpleMap from '../../views/SimpleMap';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneContactSection";

class ContactSection extends Component {
    state = {
        data: {},
        iconList: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    iconList: res.data.iconList
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{"We are here to guide."}</h2>
                        {/* <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p> */}
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-5">
                        {/* Contact Us */}
                        <div className="contact-us">
                            {/* <p className="mb-3">{this.state.data.content}</p> */}
                            <ul>
                               
                                        <li className="py-2">
                                            <a className="media" >
                                                <div className="social-icon mr-3">
                                                    <i className={"fas fa-home"} />
                                                </div>
                                                <span className="media-body align-self-center">Plot 251, Sector 24, Korangi Industrial Area, Karachi</span>
                                            </a>
                                        </li>
                                        <li className="py-2">
                                            <a className="media" >
                                                <div className="social-icon mr-3">
                                                    <i className={"fas fa-phone-alt"} />
                                                </div>
                                                <span className="media-body align-self-center">+923346518822</span>
                                            </a>
                                        </li>
                                        <li className="py-2">
                                            <a className="media" >
                                                <div className="social-icon mr-3">
                                                    <i className={"fas fa-envelope"} />
                                                </div>
                                                <span className="media-body align-self-center">info@fridgles.com</span>
                                            </a>
                                        </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                      <img src="../../Fridgles Brochure/Capture.png"></img>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;