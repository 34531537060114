import React, { PureComponent } from 'react'
import axios from 'axios';
import Hidden from "@material-ui/core/Hidden/Hidden"
import Slider from "./SliderJumbotron"

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneDiscoverSection";


class RefrigerationControllers extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            discoverData: [],
            discoverIcon: []
        }
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    discoverData: res.data.discoverData,
                    discoverIcon: res.data.discoverIcon
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }

    render() {
        return (
            <section className="section bg-gray discover-area overflow-hidden ptb_84">
                   <div className="container-fluid" id="refrigeration-banner-container">
                <div className="col-12 col-lg-12" id="refrigeration-banner-col">
                                <div className="service-thumb pt-lg-0">
                                <div className="jumbotron jumbotron7">
                                    <div className="centered"><h3>WIDE RANGE OF COLD ROOM ACCESSORIES <button className=" btn btn-custom" onClick={this.scrollSmoothHandler}>DISCOVER</button></h3>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-12" >
                               <div className="row " id="inquiries">
                               <div className="col-lg-2 offset-lg-2 text-center">
                                      <h3>For Inquiries:</h3>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="tel:+923005924250">
                                           <p><i className="fa fa-phone mr-4"></i>+923005924250</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center">
                                       <a href="mailto:info@fridgles.com">
                                           <p><i className="fa fa-envelope mr-4" id="problem"></i>info@fridgles.com</p>
                                       </a>
                                   </div>
                                   <div className="col-lg-2 text-center" ref={this.scrollDiv}>  
                                       <a href="https://www.linkedin.com/in/fridgles-integrated-solutions-0429a11b0/" target="_blank">
                                           <p><i className="fab fa-linkedin-in mr-4"></i>Linkedin profile</p>
                                       </a>
                                   </div>
                                   
                               </div>
                            </div>
                </div>
                <div className="container mt-5">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                        {/* Discover Text */}
                        <div className="discover-text pt-4 pt-lg-0">
                        <h2 className="pb-4 pb-sm-0">Refrigeration Controllers</h2>
                        <p className="pt-3">Advance refrigeration controllers are available for various industrial and commercial applications
                        in displays, incubators and vehicles applications.</p>
                        <p>They are programmable for cold and heat display and control in <sup>o</sup>C and <sup>o</sup>F with decimal point.</p>
                        <p>NTC/PTC probe selectable by program, defrost by compressor wizard depending on application (8 configurations) and up to 4 Relays.</p>
                        <h3 className="pt-4 pb-3">Types of Controllers:</h3>
                                    {/* Check List */}
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <ul className="check-list">
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Temperature/Humidity/Pressure or Flow Controllers</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Temperature Controller for Large Dimensions</span>
                                                    </div>
                                                </li>
                                                <li className="py-2">
                                                    {/* List Box */}
                                                    <div className="list-box media">
                                                        <span className="icon align-self-center"><i className="fas fa-check"/></span>
                                                        <span className="media-body pl-2">Advance Controllers with Monitoring System</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h3 className="pt-4 pb-3">Advance Temperature Controllers</h3>
                                    <p className="pb-3">They are used to optimize cold rooms and efficiently regulates all cold rooms loads
                                    including defrosts, door frame and drain resistors, fan management, compressor starts and door and 
                                    lighting management. It's SELFDRIVE function evaluates at all times the operation and use of the cold 
                                    room to automatically auto parameterize without the need for complex programming.</p>
                                    <p className="pt-2">They can also be used for monitoring system through cloud server and mobile application.</p>
                                </div>
                            </div>
                        
                        <Hidden smDown> 
                            <div className="col-12 col-lg-6">
                                <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                                    <Slider/>
                                    {/* <img src="Fridgles Brochure/Corrugated-insulated-panels.jpg" alt="" /> */}
                                </div>
                            </div>
                        </Hidden>
                        
                    </div>
                </div>
            </div>
            </section>
        );
    }
}

export default RefrigerationControllers






