import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Hidden from "@material-ui/core/Hidden/Hidden"
import SliderJumbotron from "./SliderJumbotron"
class Jumbotron extends Component {
    render() {
        return (
            <div>
                {/* <Hidden xsDown>
                    <section className="section breadcrumb-area bg-overlay1 d-flex align-items-center" style={{ background: `rgba(0, 0, 0, 0) url("/Fridgles Brochure/Cold Room Panels.png") no-repeat scroll center center / cover`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                                        <h3 className="text-white">{this.props.title}</h3>
                                        <ol className="breadcrumb justify-content-center">
                                            <li className="breadcrumb-item"><Link className="text-white" to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><Link className="text-white" to="/BlogTwoColumn/">Blog Pages</Link></li>
                                            <li className="breadcrumb-item active text-white">{this.props.title}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Hidden> */}
                <Hidden mdUp>   
                    <SliderJumbotron/>
                </Hidden>
            </div>
        );
    }
}

export default Jumbotron;